
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import {
  addNewCompany,
  getServicesList,
  getCustomersList,
  updateCompany,
} from "@/store/api/devices";
import {
  ErrorMessage,
  Field,
  Form,
  useResetForm,
  FormActions,
} from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { id } from "date-fns/locale";
import moment from "moment-timezone";

interface Customers {
  billing_address: string;
  billing_cycle: string;
  contact_person: string;
  customer_type: string;
  delivery_address: string;
  email: string;
  max_users: string;
  name: string;
  phone_number: string;
  token: string;
  vat_number: string;
  service_id: number;
  parent_id: number;
  is_kpi: any;
  group_name: any;
  time_zone: string;
}

export default defineComponent({
  name: "create-api-key-modal",
  props: {
    comp: { type: Object },
  },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  computed: {
    timezones() {
      return moment.tz.names();
    },
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const cusinfo = ref([]);
    const tempStore = ref([]);
    var idstore = ref([]);
    const modalRef = ref<null | HTMLElement>(null);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const services = ref<any[]>([]);
    const groupName = ref("");
    const parentList = ref<any[]>([]);
    const loading = ref<boolean>(false);
    // const deviceRes = ref();
    // const errors = ref<any[]>([]);
    const customer = ref<Customers>({
      billing_address: "",
      billing_cycle: "",
      contact_person: "",
      customer_type: "",
      delivery_address: "",
      email: "",
      max_users: "",
      name: "",
      phone_number: "",
      token: "",
      vat_number: "",
      service_id: 0,
      parent_id: 0,
      is_kpi: false,
      group_name: [],
      time_zone: "",
    });
    const editCustomer = ref<any>(props.comp);
    const validationSchemaInternal = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      email: Yup.string().required().label("Email"),
      phone_number: Yup.string().required().label("Phone Number"),
      max_users: Yup.string().label("Max Users"),
      token: Yup.string().required().label("Token"),
      customer_type: Yup.string().label("Type"),
      service_id: Yup.number().required().label("Package"),
      is_kpi: Yup.boolean().label("KPI"),
      group_name: Yup.string().label("Group Name"),
      parent_id: Yup.number().label("Sub Company of"),
      time_zone: Yup.string()
        .required()
        .label("Time Zone")
        .typeError("Time Zone is required"),
    });
    const validationSchemaExternal = Yup.object().shape({
      name: Yup.string().required().label("Name").typeError("Name is required"),
      email: Yup.string()
        .required()
        .label("Email")
        .typeError("Email is required"),
      phone_number: Yup.string()
        .required()
        .label("Phone Number")
        .typeError("Phone Number is required"),
      token: Yup.string()
        .required()
        .label("Token")
        .typeError("Token is required"),
      billing_address: Yup.string()
        .required()
        .label("Billing Address")
        .typeError("Billing Address is required"),
      customer_type: Yup.string().label("Type"),
      delivery_address: Yup.string()
        .required()
        .label("Delivery Address")
        .typeError("Delivery Address is required"),
      vat_number: Yup.string()
        .required()
        .label("Vat Number")
        .typeError("Vat Number is required"),
      billing_cycle: Yup.string().optional().label("Billing Cycle"),
      service_id: Yup.number()
        .required()
        .label("Package")
        .typeError("Package is required"),
      parent_id: Yup.number().label("Sub Company of"),
      is_kpi: Yup.boolean().label("KPI"),
      group_name: Yup.string().label("Group Name"),
      time_zone: Yup.string()
        .required()
        .label("Time Zone")
        .typeError("Time Zone is required"),
    });
    onMounted(async () => {
      getServicesList().then((response) => {
        services.value = response.data;
      });
      getCustomersList().then((res) => {
        parentList.value = res;
      });
      const timezones = moment.tz.names();
    });

    watch(
      () => props.comp,
      (v) => {
        editCustomer.value = v;
        if (v?.id) {
          customer.value = editCustomer.value;
        } else {
          customer.value = {
            billing_address: "",
            billing_cycle: "",
            contact_person: "",
            customer_type: "",
            delivery_address: "",
            email: "",
            max_users: "",
            name: "",
            phone_number: "",
            token: "",
            vat_number: "",
            service_id: 0,
            parent_id: 0,
            is_kpi: false,
            group_name: [],
            time_zone: "",
          };
        }
      }
    );
    const getRandomString = () => {
      customer.value.token = "";
      var randomChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let length = 6;
      for (var i = 0; i < length; i++) {
        customer.value.token += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }
      return customer.value.token;
    };
    const submit = () => {
      console.log("Cus val", customer.value);
      console.log("Submit", submitButtonRef.value);
      if (!submitButtonRef.value) {
        return;
      }
      loading.value = true;

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      if (customer.value.parent_id == 0) {
        customer.value.is_kpi = customer.value.is_kpi == true ? 1 : 0;
        if (editCustomer.value.id) {
          updateCompany(editCustomer.value?.id, customer.value).then((resp) => {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
            loading.value = false;
            Swal.fire({
              text: "Save Successfylly",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            document.getElementById("addcustomermodal")?.click();
          });
        } else {
          addNewCompany(customer.value).then((resp) => {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
            loading.value = false;
            Swal.fire({
              text: "Save Successfylly",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            document.getElementById("addcustomermodal")?.click();
          });
        }
      } else {
        parentList.value.map((companyRecord) => {
          if (companyRecord.id == customer.value.parent_id) {
            if (companyRecord.service_id == 2) {
              customer.value.is_kpi = customer.value.is_kpi == true ? 1 : 0;

              if (editCustomer.value.id) {
                updateCompany(editCustomer.value?.id, customer.value).then(
                  (resp) => {
                    if (submitButtonRef.value) {
                      submitButtonRef.value.disabled = false;
                      submitButtonRef.value?.removeAttribute(
                        "data-kt-indicator"
                      );
                    }
                    Swal.fire({
                      text: "Save Successfylly",
                      icon: "success",
                      buttonsStyling: false,
                      confirmButtonText: "Ok, got it!",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                    });
                    document.getElementById("addcustomermodal")?.click();
                  }
                );
              } else {
                addNewCompany(customer.value).then((resp) => {
                  if (submitButtonRef.value) {
                    submitButtonRef.value.disabled = false;
                    submitButtonRef.value?.removeAttribute("data-kt-indicator");
                  }
                  Swal.fire({
                    text: "Save Successfylly",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                  document.getElementById("addcustomermodal")?.click();
                });
              }
            } else {
              Swal.fire({
                text: "Please upgrade the Parent Company`s package",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              document.getElementById("addcustomermodal")?.click();
            }
          }
        });
      }
    };

    const addGroup = () => {
      if (groupName.value.trim() !== '') {
        customer.value.group_name.push(groupName.value.trim());
        console.log("Add Group", customer.value);
        groupName.value = ''; // Clear input box after adding
      }
    }
    const deleteGroup = (index) => {
      customer.value.group_name.splice(index, 1);
      console.log("Delete Group", customer.value);
    }
    const testFunction = () => {
      console.log("Test Data", customer.value);
    }
    return {
      customer,
      validationSchemaInternal,
      validationSchemaExternal,
      submit,
      submitButtonRef,
      modalRef,
      getRandomString,
      createAPIKeyModalRef,
      services,
      parentList,
      cusinfo,
      tempStore,
      editCustomer,
      idstore,
      groupName,
      addGroup,
      deleteGroup,
      loading,
      testFunction
    };
  },
});
