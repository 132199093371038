<template>
  <!--begin::Modal - New Card-->
  <!--waste type model in companies-->
  <button
    type="button"
    class="btn btn-primary mx-3"
    data-bs-toggle="modal"
    data-bs-target="#waste_pop_up"
  >
    Waste Type
  </button>
  <div
    class="modal fade"
    ref="submitButtonRef"
    id="waste_pop_up"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px roundeda">
      <!--begin::Modal content-->
      <div class="modal-content roundeda">
        <!--begin::Modal header-->
        <div class="modal-header bg-primary">
          <!--begin::Modal title-->
          <span class="text-white text-center ms-3 fs-5">Waste Types</span>
          <button
            type="button"                                                                                                                                                                                                                                           
            class="btn btn-primary"
            @click="toggle = !toggle" 
            v-if="permissions.includes('add-waste-type')"
          >
            + Waste Type
          </button>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div
            class="display_none btn btn-sm btn-icon btn-active-color-dark bg-white rounded-circle"
            data-bs-dismiss="modal"
            id="addnewmodalclose"
          >
            <span class="svg-icon svg-icon-1 text-primary fs-6"> X </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <div class="card-body pt-0 mt-4" style="width: 100%">
          <div v-if="toggle">
            <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group id="input-group-2" label-for="input-2"
                ><p class="fs-4 text-primary">Waste Type</p>
                <b-form-input
                  class="border border-primary"
                  id="input-2"
                  placeholder="Enter Waste type name"
                  v-model="payload.name"
                  required
                ></b-form-input>
              </b-form-group>
              <p class="my-4 text-primary fs-5">Select Waste Type Icon</p>
              <label class="label" v-for="(url, key) in items" :key="key">
                <input
                  type="radio"
                  class="bg-primary"
                  name="nature"
                  v-model="payload.icon"
                  :value="url.icon"
                />
                <img class="imgSize mb-4" :src="url.icon" />
              </label>

              <br />
              <b-button type="submit" variant="primary"
                >Submit
                <b-spinner
                  v-if="loading"
                  class="mt-2"
                  small
                  label="Small Spinner"
                ></b-spinner
              ></b-button>
              <b-button
                type="reset"
                style="margin-left: 10px"
                variant="danger"
                @click="toggle = !toggle"
                >Reset</b-button
              >
            </b-form>
          </div>
          <div>
            <b-form-select
              v-model="assign_value.company_id"
              class="w-50 float-end mb-4 mt-5"
              v-if="!toggle"
              @change="waste_types_of_company($event)"
            >
              <b-form-select-option :value="null"
                >Please Select Company</b-form-select-option
              >
              <option
                v-for="(option, idx) in customer_list"
                :key="idx"
                :value="option.id"
                :title="option.name"
              >
                {{ `${option.name}(${option.id})` }}
              </option>
            </b-form-select>
          </div>
          <b-table v-if="!toggle" :items="items" :fields="header">
            <template #cell(checkbox)="data">  
              <b-form-checkbox
                v-if="!items[data.index].isEdit"
                v-model="assign_value.waste_type_ids"
                :value="data.item.id"
                :checked="
                  assign_value.waste_type_ids.includes(data.item.id)
                    ? 1
                    : 0
                "
              ></b-form-checkbox>
              <span v-else>{{ data.item.id }}</span>
            </template>
            <template #cell(name)="data">
              <b-form-input
                v-if="items[data.index].isEdit"
                type="text"
                v-model="items[data.index].name"
              ></b-form-input>
              <span v-else>{{ data.value }}</span>
            </template>
            <template #cell(icon)="data">
              <span v-if="items[data.index].isEdit">
                <label
                  v-for="(url, i) in data.items"
                  :key="i"
                  class="label mb-3"
                >
                  <input
                    type="radio"
                    v-model="items[data.index].icon"
                    name="nature"
                    :value="url.icon"
                  />
                  <img :src="url.icon" />
                </label>
              </span>
              <span class="bg-icons" v-else>
                <img :src="data.item.icon" alt="waste type pic" />
              </span>
            </template>

            <template #cell(edit)="data" class="action-handler">
              <!-- <b-button @click="editRowHandler(data)" class="permission-action">
                <span
                  v-if="!items[data.index].isEdit"
                  :class="`badge badge-light-success modal-badge`"
                >
                  <i class="fas fa-edit text-success"></i>
                </span>
                <span v-else :class="`badge badge-light-success modal-badge`"
                  ><i
                    @click="updateWasteTypeData(data)"
                    class="fas fa-check-circle text-success fa-5x"
                  ></i
                ></span>
              </b-button> -->

              <b-button class="permission-action">
                <span
                  v-if="!items[data.index].isEdit"
                  :class="`badge badge-light-success modal-badge`"
                >
                  <span v-if="permissions.includes('delete-waste-type')">
                    <i
                      @click="delete_Waste_Type(data)"
                      class="fas fa-trash text-danger"
                      style="margin-bottom: 6px"
                    ></i>
                  </span>
                </span>
                <span
                  v-if="items[data.index].isEdit"
                  :class="`badge badge-light-success modal-badge`"
                >
                  <i
                    @click="notEdit(data)"
                    class="fas fa-times text-danger"
                    aria-hidden="true"
                  ></i>
                </span>
              </b-button>
              <!-- v-if="loading && feature_id == data.item.id" -->
              <b-spinner
                v-if="loading && waste_type_id == data.item.id"
                class="mt-2"
                small
                label="Small Spinner"
              ></b-spinner>
            </template>
          </b-table>
          <span v-if="permissions.includes('assign-comp-wastetype')">
            <b-button
              v-if="!toggle"
              class="float-end"
              variant="primary"
              @click="assign_Waste_Type_to_Company(assign_value)"
              >Saves
              <b-spinner
                v-if="saveloading"
                class="mt-2"
                small
                label="Small Spinner"
              ></b-spinner
            ></b-button>
          </span>
          <pre></pre>
        </div>
        <!--begin::Modal body-->

        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>

  <!--end::Modal - New Card-->
</template>
<script>
import { defineComponent, onMounted, ref } from "@vue/runtime-core";
import {
  addwasteType,
  updatewasteType,
  deletewasteType,
  wasteType_list,
  getCustomersList,
  assignwasteType,
  wasteTypeByCompany,
} from "@/store/api/devices";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default defineComponent({
  name: "editpermission",
  setup() {
    const items = ref([]);
    const permissions = ref([]);
    const waste_type_id = ref("");
    const searchValue = ref();
    const loading = ref(false);
    const saveloading = ref(false);
    const toggle = ref(false);
    const customer_list = ref([]);
    const header = ref([
      { key: "checkbox", label: "" },
      { key: "name", label: "Name" },
      { key: "icon", label: "Icon" },
      { key: "edit", label: "" },
    ]);
    const payload = ref({ name: "", icon: "" });
    const assign_value = ref({
      company_id: "",
      waste_type_ids: [],
    });
    const deleteIcon = ref(false);
    onMounted(async () => {
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
      items.value.map((item) => ({ ...item, isEdit: false }));
      const feature = await getCustomersList();
      customer_list.value = feature;
      loadData();
    });
    const loadData = async () => {
      const result = await wasteType_list();
      items.value = result.data;
    };
    const onSubmit = async (event) => {
      event.preventDefault();
      loading.value = true;
      console.log(payload.value);
      let res = await addwasteType(payload.value);
      if (res.success === true) {
        console.log(payload.value);
        toggle.value = false;
      }
      loading.value = false;
      loadData();
    };
    const editRowHandler = async (data) => {
      items.value[data.index].isEdit = !items.value[data.index].isEdit;
    };
    const updateWasteTypeData = async (data) => {
      console.log("data", data);
      if (items.value[data.index].isEdit === true) {
        waste_type_id.value = data.item.id;
        loading.value = true;
        let payload = {
          name: data.item.name,
          icon: data.item.icon,
        };
        console.log("payload with icon=>", payload);
        const result = await updatewasteType(data.item.id, payload);
        console.log("now you update the value", result);
        loadData();
        loading.value = false;
      }
    };
    const notEdit = (data) => {
      items.value[data.index].isEdit = false;
    };
    const delete_Waste_Type = (data) => {
      console.log(data);
      Swal.fire({
        title: "Are you sure?",
        type: "danger",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes, delete it!",
        closeOnConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          waste_type_id.value = data.item.id;
          loading.value = true;
          const resp = await deletewasteType(data.item.id);
          loadData();
          console.log(resp);
          loading.value = false;
        }
      });
    };
    const waste_types_of_company = async (event) => {
      loading.value = true;
      const resp = await wasteTypeByCompany({ company_id: event });
      assign_value.value.waste_type_ids = resp.data;
      loading.value = false;
    };

    const assign_Waste_Type_to_Company = async (val) => {
      let id = val.company_id;
      let res = id.toString();
      saveloading.value = true;
      assign_value.value.company_id = res;
      const result = await assignwasteType(assign_value.value).then(() => {
        document.getElementById("addnewmodalclose").click();
      });
      console.log(result);
      loadData();
      saveloading.value = false;
    };

    return {
      header,
      onSubmit,
      editRowHandler,
      updateWasteTypeData,
      notEdit,
      delete_Waste_Type,
      items,
      customer_list,
      deleteIcon,
      waste_type_id,
      loading,
      saveloading,
      assign_value,
      payload,
      searchValue,
      toggle,
      permissions,
      waste_types_of_company,
      assign_Waste_Type_to_Company,
    };
  },
});
</script>
<style scoped>
.btn.btn-secondary.permission-action {
  padding: 3px !important;
  background: #fff !important;
}
.btn.btn-secondary.permission-action:hover {
  background-color: #fff !important;
  background: #fff !important;
}
.btn.btn-secondary.permission-action:focus {
  background-color: #fff !important;
  background: #fff !important;
}
.badge-light-success.modal-badge {
  padding-top: 4px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  padding-right: 4px !important;
}
/* .table td:last-child {
 display: flex !important;
} */
.display_none {
  display: none;
}
.display_block {
  display: block;
}
span.spinner-border.text-primary {
  z-index: 1;
  position: absolute;
  top: 227px;
  right: 50%;
  justify-content: center;
  display: flex;
}
[type="radio"] {
  position: absolute;
  opacity: 0;
}
[type="radio"] + img {
  cursor: pointer;
  margin-right: 0.5rem;
}
[type="radio"]:checked + img {
  background-color: #0d0c0c;
}
.label {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  transition: all 100ms ease-in;
  filter: brightness(0.9) grayscale(1.7) opacity(0.9) !important;
}

.imgSize {
  margin: 0px;
  width: 37px;
  padding: 0px;
  height: 37px;
}
img {
  width: 28px;
  height: 29px;
}
.float-end {
  border-color: #009ef7;
}
</style>
