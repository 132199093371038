
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { updateThreshold } from "@/store/api/devices";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";
interface Chan_pass {
  password: string;
  confirm_password: string;
}
export default defineComponent({
  name: "UpdateThreshold",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    customer: { required: true },
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const customer = ref();
    const threshold = ref(0);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    watch(
      () => props.customer,
      (v) => {
        customer.value = v;
        threshold.value = customer.value.threshold;
      }
    );
    const valid = Yup.object().shape({
      threshold: Yup.number()
        .required()
        .label("Puickup Threshold")
        .typeError("Puickup Threshold number is required"),
    });

    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      // if (payload.value.password === payload.value.confirm_password) {

      await updateThreshold({
        id: customer.value.id,
        threshold: threshold.value,
      }).then((res) => {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
        document.getElementById("thresholdmodal")?.click();
      });
      // }
    };

    return {
      threshold,
      valid,
      submit,
      createAPIKeyModalRef,
      submitButtonRef,
      modalRef,
    };
  },
});
